import React from 'react'
import { Link, navigate } from "gatsby";

import {
  Seo,
  Zoom,
} from '@components'
import { PageLayout } from '../../../components/site/layout/page-layout';
import { QuickstartGrid } from '../../../components/site/quickstarts';
import { 
  RightSidebar, 
  BackendApiProtectionImage,
  AdvancedAPIProtectionBanner, 
  BackendApiIntegrationImage 
} from '../../../components/site/product';

import { BenefitCard } from "../../../components/core/benefit-card";
import { APIAbuseIcon } from '../../../components/core/icons/api_abuse'
import { TokenSecurityIcon } from '../../../components/core/icons/token_security'
import { BackendIntegrationIcon } from '../../../components/core/icons/backend_integration'

const SecureAPIAuthenticationPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <AdvancedAPIProtectionBanner title="Advanced API Protection"/>

      <div className="container pt-[6rem] pb-[70px] text-[#262727]">
        <div className="w-full flex flex-col lg:flex-row px-[32px] gap-[38px]">
          {/* Left Side */}
          <div className="w-full">
              <div className="flex flex-col px-[20px] md:px-[28px] py-[30px] md:py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Advanced API Protection
                  </h2>
                  <div className="w-full xs:w-[230px] h-[4px] bg-[#B21C7A]"></div>
                  <p className="text-[17px] leading-[29px] m-0">               
                    Advanced API Protection allows endpoints to be locked down so that they will only respond to your official app, running in trusted environments, and nothing else. This blocks a wide range of attacks which are otherwise possible by spoofing API requests in various ways.
                  </p>
              </div>

              <h2 className="mt-[50px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Benefits</h2>
              <BenefitCard 
                className="mt-[20px]"
                Icon={
                  <TokenSecurityIcon className="w-[55px] h-[55px] flex-shrink-0" />
                }
                title="Token Based Security"
                text="Limited lifetime token based security with automatic token renewal"
              />
              <div className="mt-[25px] w-full flex flex-col md:flex-row gap-[18px]">
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <APIAbuseIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Block All Forms of API Abuse"
                  text="Block all forms of API abuse by proving your official app is present"
                />
                <BenefitCard 
                  className="md:w-1/2"
                  Icon={
                    <BackendIntegrationIcon className="w-[55px] h-[55px] flex-shrink-0" />
                  }
                  title="Simple Backend Integration"
                  text="Simple backend integration using industry standard JWTs"
                />
              </div>
              <div className="w-full flex justify-center mt-[40px]">
                <button className="text-[16px] text-[#fff] font-medium leading-[28px] bg-[#78A65A] px-[38px] py-[16px] rounded-[7px]"
                  onClick={() => {
                    navigate('https://approov.io/info/schedule-a-demo')
                  }}
                >
                  Request a Demo
                </button>
              </div>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Securing Your APIs</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov protects your backend APIs from API abuse, credential stuffing, fake botnet registrations, and DDoS attacks. And it protects the 3rd party APIs you use too.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov performs an ongoing, deep inspection of your mobile app and the device it is running upon, and based on this guarantees authenticity of requests to your backend APIs and services, using an Industry standard signed JSON Web Token (JWT).
              </p>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Short Lived Cryptographic Tokens</h2>
              <Zoom>{BackendApiProtectionImage}</Zoom>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Backend API Integration</h2>
              <p className="mt-[20px] mb-[20px] text-[17px] leading-[29px]">
                Backend API integration is only necessary if you have your own API backend and are using Approov tokens. If you are using Approov to protect API keys using <Link href="https://approov.io/mobile-app-security/rasp/runtime-secrets/" className="">Runtime Secrets Protection</Link> then no backend API integration is needed at all.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Approov tokens are added to your API request headers, and your backend API systems need to be enhanced to verify these tokens. How you handle invalid or missing Approov tokens is up to you — you might reject the requests, rate limit the access, or enable additional security measures. Approov provides the flexibility to balance your security needs against API accessibility.
              </p>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Token verification is straightforward because the tokens are in the industry standard JWT format. Your code just needs to make a library call to check that each token has been correctly signed for your account, and that it has not expired.
              </p>
              <Zoom>{BackendApiIntegrationImage}</Zoom>

              <h2 className="mt-[40px] text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">Backend API Quickstarts</h2>
              <p className="mt-[20px] mb-[30px] text-[17px] leading-[29px]">
                Integrating Approov into backend services is straightforward, and Quickstart guides are provided for popular platforms below. If your platform is not listed, see <Link href="https://approov.io/mobile-app-security/rasp/runtime-secrets/" className="">Backend Integration</Link> or <Link href="https://approov.io/mobile-app-security/rasp/runtime-secrets/" className="">Contact Us</Link>.
              </p>
              <QuickstartGrid tag="backend" />

              {/* <div className="mt-[70px] flex justify-center px-[90px] py-[40px] items-start gap-[15px] rounded-[10px] bg-[#F2F5F9]">
                  <h2 className="text-center text-[31px] text-[#1B3D57] font-semibold leading-[43px] m-0">
                    Recommended Resources About Approov Mobile RASP Security
                  </h2>
              </div> */}
          </div>
          {/* Right Side */}
          <RightSidebar location={location.pathname} />
        </div>
      </div>
    </PageLayout>
  )
}

export default SecureAPIAuthenticationPage
