import React from 'react'
import classNames from 'classnames'

const BenefitCard = ({ className, title, text, Icon, ...rest }) => {

  return (
    <div
      className={classNames('w-full flex flex-col gap-[10px] px-[20px] md:px-[28px] py-[30px] md:py-[40px] rounded-[10px] border-[1px] border-[#E3E3E3]', className)}
    >
        <div className="flex items-center gap-[18px]">
            {Icon}
            <span className="text-[20px] font-semibold text-[#1B3D57] leading-[24px]">{title}</span>
        </div>
        <p className="text-[16px] leading-[28px] m-0">
            {text}
        </p>
    </div>
  )
}

export { BenefitCard }
